import { toggleExpanded, toggleHiddenUntilFound } from 'src/utils/dom-toggle';
import { isReducedMotion } from 'src/utils/media-query';
import { Context, Controller } from '@hotwired/stimulus';

/**
 * Allows you to create a combination of trigger and content that can
 * be collapsed or expanded.
 *
 * If there is no no-js fallback, use `aria-expanded="true"` or add the `hidden`
 * attribute to the content. Also add the `js-only` class to the button, so it
 * only appear to be toggle-able if JavaScript is enabled.
 *
 * @example The HTML for a minimal example
 *
 * <div data-controller="collapse">
 *  <button
 *    type="button"
 *    aria-controls="id-of-the-content"
 *    aria-expanded="true"
 *    data-collapse-target="trigger"
 *    data-action="collapse#onToggle"
 *  >
 *    <span> Label </span>
 *    <span>
 *      <svg data-collapse-target="icon" class="transition duration-100 ease-in-out -rotate-180 h-5 w-5 transform" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
 *        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
 *      </svg>
 *    </span>
 *  </button>
 *
 *  <div id="id-of-the-content" data-collapse-target="content">
 *    ...
 *  </div>
 * </div>
 */
export default class CollapseController extends Controller {
  public static targets = ['icon', 'content', 'trigger'];
  public static values = {
    initial: Boolean,
  };

  private declare iconTarget: HTMLElement;
  private declare contentTargets: readonly HTMLElement[];
  private declare triggerTarget: HTMLElement;
  private declare initialValue?: boolean;
  private declare hasInitialValue: boolean;

  constructor(context: Context) {
    super(context);

    this.onBeforeMatchContent = this.onBeforeMatchContent.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  public connect(): void {
    const initialOpen = this.hasInitialValue && this.initialValue;

    // Make sure it's closed
    toggleExpanded(this.triggerTarget, initialOpen);
    this.contentTargets.forEach((contentTarget) => {
      toggleHiddenUntilFound(contentTarget, !initialOpen);
      contentTarget.addEventListener('beforematch', this.onBeforeMatchContent);
    });

    // Make sure animations are set-up
    this.iconTarget.classList.toggle('-rotate-180', initialOpen);
    this.iconTarget.classList.toggle('rotate-0', !initialOpen);
  }

  public disconnect(): void {
    toggleExpanded(this.triggerTarget, true);
    this.contentTargets.forEach((contentTarget) => {
      toggleHiddenUntilFound(contentTarget, false);
      contentTarget.removeEventListener(
        'beforematch',
        this.onBeforeMatchContent,
      );
    });

    this.iconTarget.classList.add('-rotate-180');
    this.iconTarget.classList.remove('rotate-0');
  }

  public get opened(): boolean {
    return this.triggerTarget.getAttribute('aria-expanded') === 'true';
  }

  public set opened(next: boolean) {
    toggleExpanded(this.triggerTarget, next);

    if (next) {
      this.contentTargets.forEach((contentTarget) =>
        toggleHiddenUntilFound(contentTarget, false),
      );
    } else {
      // Timeout allowed for animation
      setTimeout(() => {
        if (this.opened) {
          return;
        }

        this.contentTargets.forEach((contentTarget) =>
          toggleHiddenUntilFound(contentTarget, true),
        );

        this.triggerTarget.scrollIntoView({
          behavior: isReducedMotion() ? 'auto' : 'smooth',
          block: 'nearest',
        });
      }, 100);

      // Focus the collapse button
      this.triggerTarget.focus({ preventScroll: true });
    }

    requestAnimationFrame(() => {
      if (!this.triggerTarget) {
        return;
      }

      this.iconTarget.classList.toggle('rotate-0', !next);
      this.iconTarget.classList.toggle('-rotate-180', next);
    });
  }

  public onOpen(): void {
    this.opened = true;
  }

  public onClose(): void {
    this.opened = false;
  }

  public onToggle(): void {
    this.opened = !this.opened;
  }

  public onBeforeMatchContent(event: Event): void {
    if (this.opened) {
      return;
    }

    this.triggerTarget.click();

    if (event.target instanceof HTMLElement) {
      event.target.scrollIntoView({ behavior: 'auto', block: 'nearest' });
    }
  }
}
