import { type Context, Controller } from '@hotwired/stimulus';

export default class EditorController extends Controller {
  static targets = ['categoryIdentifier', 'categoryIdentifierMirror'];

  declare categoryIdentifierTarget: HTMLInputElement;
  declare categoryIdentifierMirrorTargets: readonly HTMLElement[];

  constructor(context: Context) {
    super(context);

    this.onChangeCategoryIdentifier =
      this.onChangeCategoryIdentifier.bind(this);
  }

  connect(): void {
    this.categoryIdentifierTarget.addEventListener(
      'input',
      this.onChangeCategoryIdentifier,
    );
  }

  disconnect(): void {
    this.categoryIdentifierTarget.removeEventListener(
      'input',
      this.onChangeCategoryIdentifier,
    );
  }

  private get categoryIdentifier() {
    return this.categoryIdentifierTarget.value;
  }

  public categoryIdentifierMirrorTargetConnected(target: HTMLElement) {
    target.textContent = this.categoryIdentifier + '/';
  }

  private onChangeCategoryIdentifier() {
    const newValue = `${this.categoryIdentifier + '/'}`;

    this.categoryIdentifierMirrorTargets.forEach((target) => {
      target.textContent = newValue;
    });
  }

  public onRemoveLabel(event: Event) {
    if (!(event.currentTarget instanceof HTMLElement)) {
      return;
    }

    const labelId = event.currentTarget.getAttribute(
      'data-labels--editor-label-value-id-value',
    );
    if (labelId) {
      this.removeLabelById(labelId);
    }
  }

  private removeLabelById(labelId: string) {
    const input = this.element.querySelector(
      `input[name="label_values[${labelId}][identifier]"]`,
    );
    const boundary = input?.closest('[data-labels--editor-target="boundary"]');

    if (!boundary) {
      return;
    }

    // Remove the question completely if it was new
    if (
      boundary.querySelector(
        `input[name="label_values[${labelId}][new]"][value="1"]`,
      )
    ) {
      boundary.remove();
      return;
    }

    // Otherwise, mark as removed
    boundary.querySelectorAll('input').forEach((input) => {
      input.disabled = true;
    });

    const removeInput = document.createElement('input');
    removeInput.type = 'hidden';
    removeInput.name = `label_values[${labelId}][remove]`;
    removeInput.value = '1';

    boundary.setAttribute('hidden', '');
    boundary.append(removeInput);
  }
}
