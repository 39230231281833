import { toggleHidden } from 'src/utils/dom-toggle';
import { Controller, type Context } from 'stimulus';

export default class RangeLabelsController extends Controller {
  public static targets = ['minLabel', 'maxLabel', 'input', 'floater'];
  public static values = {
    labels: Object,
  };

  private declare labelsValue: Record<number, string>;

  private declare inputTarget: HTMLInputElement;
  private declare floaterTarget: HTMLElement;

  constructor(context: Context) {
    super(context);

    this.onInput = this.onInput.bind(this);
  }

  public connect(): void {
    this.inputTarget.addEventListener('input', this.onInput);
    this.onInput();

    super.connect();
  }

  public disconnect(): void {
    this.inputTarget.removeEventListener('input', this.onInput);

    super.disconnect();
  }

  private get minValue(): number {
    if (this.inputTarget.hasAttribute('min')) {
      return Number(this.inputTarget.getAttribute('min'));
    }

    return -Infinity;
  }

  private get maxValue(): number {
    if (this.inputTarget.hasAttribute('max')) {
      return Number(this.inputTarget.getAttribute('max'));
    }

    return Infinity;
  }

  private labelFor(value: number) {
    return this.labelsValue[value];
  }

  private onInput() {
    const currentValue = Number(this.inputTarget.value);
    const showFloater =
      currentValue > this.minValue && currentValue < this.maxValue;

    this.floaterTarget.textContent = this.labelFor(currentValue);

    toggleHidden(this.floaterTarget, !showFloater && false);
  }
}
