import { autoScrollWindowForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import {
  attachClosestEdge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import type { CleanupFn } from '@atlaskit/pragmatic-drag-and-drop/types';
import { Context, Controller } from '@hotwired/stimulus';
import { getDropIndicator } from 'src/dnd/drag-preview';
import { broadcast } from 'src/emitter';
import invariant from 'tiny-invariant';

export default class ReorderableController extends Controller {
  public static targets = ['dragHandle', 'dropZone'];

  declare dragHandleTargets: readonly HTMLElement[];

  private cleanups: Record<string, CleanupFn>;
  private connected: boolean = false;

  constructor(context: Context) {
    super(context);

    this.onDropped = this.onDropped.bind(this);

    this.cleanups = {};
  }

  private get instanceId(): string {
    if (this.element.hasAttribute('data-reorderable-instance-id')) {
      return this.element.getAttribute('data-reorderable-instance-id')!;
    }

    const instanceId =
      this.element.id || `reorderable-${Math.random().toString(36)}`;
    this.element.setAttribute('data-reorderable-instance-id', instanceId);

    return instanceId;
  }

  public connect(): void {
    this.cleanups[this.instanceId] = autoScrollWindowForElements();
    this.connected = true;
  }

  public disconnect(): void {
    this.connected = false;
    const cleanup = this.cleanups[this.instanceId];
    if (cleanup) {
      cleanup();
      delete this.cleanups[this.instanceId];
    }
  }

  public dropZoneTargetConnected(target: HTMLElement) {
    const cleanup = enableDroppable(this.instanceId, target, this.onDropped);

    const id = target.id || `drag-zone-${Math.random().toString()}`;
    target.setAttribute('id', id);
    target.setAttribute('data-cleanup-id', id);
    target.setAttribute('data-instance-id', this.instanceId);
    this.cleanups[id] = cleanup;
  }

  public dropZoneTargetDisconnected(target: HTMLElement) {
    const cleanup = this.cleanups[target.id];

    if (cleanup) {
      cleanup();
      delete this.cleanups[target.id];
    }

    this.onDropped();
  }

  public dragHandleTargetConnected(target: HTMLElement) {
    const cleanup = attach(
      this.instanceId,
      target.closest('[data-reorderable-group]') as HTMLElement,
      this.onDropped,
    );

    const id = target.id || `drag-handle-${Math.random().toString()}`;
    target.setAttribute('id', id);
    target.setAttribute('data-cleanup-id', id);
    target.setAttribute('data-instance-id', this.instanceId);
    this.cleanups[id] = cleanup;

    // TODO deal with this
    this.onDropped();
  }

  public dragHandleTargetDisconnected(target: HTMLElement) {
    const cleanup = this.cleanups[target.id];

    if (cleanup) {
      cleanup();
      delete this.cleanups[target.id];
    }

    this.onDropped();
  }

  public onDropped() {
    if (!this.connected) {
      return;
    }

    broadcast('reordered', { element: this.element });
  }
}

function attach(
  instanceId: string,
  element: HTMLElement,
  callback: (moved: HTMLElement) => void,
): CleanupFn {
  return combine(
    enableDraggable(instanceId, element),
    enableDroppable(instanceId, element, callback),
  );
}

function enableDraggable(instanceId: string, element: HTMLElement) {
  const data = {
    instanceId,
    group: element.getAttribute('data-reorderable-group'),
  };

  const dragHandle = element.querySelector<HTMLElement>(
    `[data-reorderable-target="dragHandle"][data-reorderable-group-handle="${element.getAttribute('data-reorderable-group')}"]`,
  );

  if (!dragHandle) {
    throw new Error('missing dragHandle');
  }

  return draggable({
    element,
    dragHandle,
    getInitialData: () => data,
    onGenerateDragPreview({ nativeSetDragImage }) {
      setCustomNativeDragPreview({
        nativeSetDragImage,
        getOffset: pointerOutsideOfPreview({
          x: '16px',
          y: '8px',
        }),
        render({ container }) {
          // Dynamically creating a more reduce drag preview
          const preview = document.createElement('div');

          preview.classList.add(
            'form-card',
            'rounded-sm',
            'border',
            'dark:border-gray-700',
            'shadow-xl',
          );

          // Use a part of the element as the content for the drag preview
          preview.textContent =
            element.querySelector('[data-drag-preview]')?.textContent ??
            element.getAttribute('data-drag-preview-content') ??
            // worst case fallback if we set up our data-* up wrong
            element.textContent;

          container.appendChild(preview);
        },
      });
    },
    onDragStart() {
      element.classList.add('opacity-40');
    },
    onDrop() {
      element.classList.remove('opacity-40');
    },
  });
}

function enableDroppable(
  instanceId: string,
  element: HTMLElement,
  callback: (moved: HTMLElement) => void,
) {
  const data = {
    instanceId,
    group: element.getAttribute('data-reorderable-group'),
  };

  return dropTargetForElements({
    element,
    canDrop({ source }) {
      // cannot drop on self
      if (source.element === element) {
        return false;
      }
      if (
        source.data.instanceId === instanceId &&
        source.data.group == data.group
      ) {
        return true;
      }

      return false;
    },
    getData({ input }) {
      return attachClosestEdge(data, {
        element,
        input,
        allowedEdges: ['top', 'bottom'],
      });
    },
    getIsSticky() {
      return true;
    },
    onDragEnter({ self }) {
      const closestEdge = extractClosestEdge(self.data);
      if (!closestEdge) {
        return;
      }
      const indicator = getDropIndicator({
        tagname: 'li',
        edge: closestEdge,
        gap: '1px',
      });
      element.append(indicator);
    },
    onDrag({ self }) {
      const closestEdge = extractClosestEdge(self.data);
      if (!closestEdge) {
        element.nextElementSibling?.remove();
        return;
      }

      // don't need to do anything, already have a drop indicator in the right spot
      if (element.lastElementChild?.getAttribute('data-edge') === closestEdge) {
        return;
      }

      // get rid of the old drop indicator
      if (element.lastElementChild?.hasAttribute('data-edge')) {
        element.lastElementChild.remove();
      }

      // make a new one
      const indicator = getDropIndicator({
        tagname: 'li',
        edge: closestEdge,
        gap: '1px',
      });
      element.append(indicator);
    },
    onDragLeave() {
      if (element.lastElementChild?.hasAttribute('data-edge')) {
        element.lastElementChild.remove();
      }
    },
    onDrop({ self, source }) {
      if (element.lastElementChild?.hasAttribute('data-edge')) {
        element.lastElementChild.remove();
      }
      const closestEdgeOfTarget = extractClosestEdge(self.data);

      if (!closestEdgeOfTarget) {
        return;
      }

      const toMove = source.element;
      invariant(toMove);

      element.insertAdjacentElement(
        closestEdgeOfTarget === 'top' ? 'beforebegin' : 'afterend',
        toMove,
      );

      callback(source.element);

      triggerPostMoveFlash(source.element);
    },
  });
}

function triggerPostMoveFlash(element: HTMLElement) {
  element.animate([{ backgroundColor: 'blue' }, {}], {
    duration: 500,
    /**
     * This is equivalent to the browser default, but we are making it
     * explicit to avoid relying on implicit behavior.
     *
     * This curve is not part of `@atlaskit/motion` but it was an intentional
     * design decision to use this curve.
     */
    easing: 'cubic-bezier(0.25, 0.1, 0.25, 1.0)',
    iterations: 1,
  });
}
