import { Context, Controller } from '@hotwired/stimulus';
import { toggleHidden } from 'src/utils/dom-toggle';

export default class InputController extends Controller {
  public static targets = [
    'label',
    'input',
    'onBlank',
    'onPresent',
    'onValid',
    'onInvalid',
  ];

  private declare labelTarget: HTMLLabelElement;
  private declare inputTarget: HTMLInputElement;

  private declare onBlankTargets: readonly HTMLElement[];
  private declare onPresentTargets: readonly HTMLElement[];
  private declare onValidTargets: readonly HTMLElement[];
  private declare onInvalidTargets: readonly HTMLElement[];

  constructor(context: Context) {
    super(context);

    this.onChange = this.onChange.bind(this);

    this.clear = this.clear.bind(this);
  }

  connect(): void {
    this.inputTarget.addEventListener('change', this.onChange);

    // Initial
    this.onChange();
  }

  disconnect(): void {
    this.inputTarget.removeEventListener('change', this.onChange);
  }

  private onChange() {
    const present = !!this.inputTarget.value.trim();
    const valid = this.inputTarget.validity.valid;

    this.onBlankTargets.forEach((target) => toggleHidden(target, present));
    this.onPresentTargets.forEach((target) => toggleHidden(target, !present));
    this.onValidTargets.forEach((target) => toggleHidden(target, valid));
    this.onInvalidTargets.forEach((target) => toggleHidden(target, !valid));
  }

  public clear() {
    this.inputTarget.value = '';
    this.onChange();
  }
}
