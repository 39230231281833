import { Context, Controller } from '@hotwired/stimulus';

export default class SvgGraphController extends Controller {
  static targets = ['multiline'];

  declare multilineTargets: readonly SVGTextElement[];

  constructor(context: Context) {
    super(context);
  }

  connect(): void {
    this.multilineTargets.forEach((target) => this.wrap(target));
  }

  disconnect(): void {
    this.multilineTargets.forEach((target) => this.unwrap(target));
  }

  private wrap(target: SVGTextElement) {
    const wrapWidth = 180;
    const lineHeight = target.getBoundingClientRect().height + 2;

    let words = target.textContent?.split(' ') || [];

    // Clear contents
    target.innerHTML = '';
    let line = '';

    let tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
    tspan.setAttribute('x', target.getAttribute('x')!);
    tspan.setAttribute('dy', String(0));
    tspan.textContent = '&nbsp;';
    target.append(tspan);

    for (let n = 0; n < words.length; n++) {
      const testLine = [line, words[n]].filter(Boolean).join(' ');
      tspan.textContent = testLine;

      const metrics = tspan.getBoundingClientRect();
      const testWidth = metrics.width;

      if (testWidth > wrapWidth && n > 0) {
        tspan.textContent = line;

        line = words[n];

        tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
        tspan.setAttribute('x', target.getAttribute('x')!);
        tspan.setAttribute('dy', String(lineHeight));
        tspan.textContent = '&nbsp;';
        target.append(tspan);
      } else {
        line = testLine;
      }
    }

    if (line.length > 0) {
      tspan.textContent = line;
    }
  }

  private unwrap(target: SVGTextElement) {
    target.textContent = target.textContent + '';
  }
}
