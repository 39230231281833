import { Context, Controller } from '@hotwired/stimulus';

export default class SetInputController extends Controller {
  static values = {
    query: String,
    value: String,
  };

  declare queryValue: string;
  declare valueValue: string;

  constructor(props: Context) {
    super(props);
  }

  public onReplaceInputValue(e: Event) {
    const input = this.element
      .closest('form')
      ?.querySelector<HTMLInputElement | HTMLTextAreaElement>(this.queryValue);

    if (input) {
      input.value = this.valueValue;
    }
  }
}
