import { Context, Controller } from '@hotwired/stimulus';
import { toggleAttribute, toggleHidden } from 'src/utils/dom-toggle';

export default class ConditionalFormFieldController extends Controller {
  static values = {
    source: String,
    condition: String,
  };

  declare sourceValue: string;
  declare conditionValue: string;

  private sourceInput:
    | HTMLInputElement
    | HTMLTextAreaElement
    | HTMLSelectElement
    | null
    | undefined;

  constructor(props: Context) {
    super(props);

    this.onSourceChanged = this.onSourceChanged.bind(this);
  }

  public connect(): void {
    this.sourceInput = this.element
      .closest('form')
      ?.querySelector<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >(this.sourceValue);

    if (!this.sourceInput) {
      return;
    }

    this.sourceInput.addEventListener(
      this.sourceInput.tagName === 'select' ? 'change' : 'input',
      this.onSourceChanged,
    );

    this.onSourceChanged();
  }

  public disconnect(): void {
    if (!this.sourceInput) {
      return;
    }

    this.sourceInput.removeEventListener(
      this.sourceInput.tagName === 'select' ? 'change' : 'input',
      this.onSourceChanged,
    );
  }

  public onSourceChanged() {
    if (this.conditionValue === ':checked') {
      const should_be_disabled = !(this.sourceInput as HTMLInputElement)
        .checked;

      toggleHidden(this.element, should_be_disabled);
      this.element
        .querySelectorAll('input,textarea,select')
        .forEach((input) => {
          toggleAttribute('readonly', input, should_be_disabled);
        });
      return;
    }
  }
}
